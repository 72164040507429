// density of 4 for the lowest probability of collision
const SQUARE_DENSITY = 4
// 12 different hue colors only for easy distinction
const COLORS_NB = 12
const DEFAULT_SATURATION = 50
const DEFAULT_LIGHTNESS = 50

// 32 bit FNV-1a hash parameters
const FNV_PRIME = 16777619
const OFFSET_BASIS = 2166136261

/**
 * @type {(str: string) => number}
 */
// based on the FNV-1a hash algorithm, modified for *signed* 32 bit integers http://www.isthe.com/chongo/tech/comp/fnv/index.html
function simpleHash(str) {
    return str.split('')
        // >>> 0 for 32 bit unsigned integer conversion https://2ality.com/2012/02/js-integers.html
        .reduce((hash, char) => ((hash ^ char.charCodeAt(0)) >>> 0) * FNV_PRIME, OFFSET_BASIS)
}

/**
 * @type {import('.').identicon}
 */
export function identicon(username="", saturation=DEFAULT_SATURATION, lightness=DEFAULT_LIGHTNESS) {
    const hash = simpleHash(username)
    // dividing hash by FNV_PRIME to get last XOR result for better color randomness (will be an integer except for empty string hash)
    const hue = ((hash / FNV_PRIME) % COLORS_NB) * (360 / COLORS_NB)
    return [...Array(username ? 25 : 0)].reduce((acc, e, i) =>
        // 2 + ((3 * 5 - 1) - modulo) to concentrate squares at the center
        hash % (16 - i % 15) < SQUARE_DENSITY ?
            acc + `<rect x="${i > 14 ? 7 - ~~(i / 5) : ~~(i / 5)}" y="${i % 5}" width="1" height="1"/>`
        : acc,
        // xmlns attribute added in case of SVG file generation https://developer.mozilla.org/en-US/docs/Web/SVG/Element/svg#sect1
        `<svg viewBox="-1.5 -1.5 8 8" xmlns="http://www.w3.org/2000/svg" fill="hsl(${hue} ${saturation}% ${lightness}%)">`
    )
    + '</svg>'
}

/**
 * @type {void}
 */
export const identiconSvg =
    // declared as a pure function to be tree-shaken by the bundler
    /*@__PURE__*/globalThis.customElements?.define('identicon-svg',
        class IdenticonSvg extends HTMLElement {
            static observedAttributes = ['username', 'saturation', 'lightness']
            // private fields to allow Terser mangling
            static #memoized = {}
            #isConnected = false
            connectedCallback() {
                this.#setContent()
                this.#isConnected = true
            }
            // attributeChangedCallback() is called for every observed attribute before connectedCallback()
            attributeChangedCallback() { if (this.#isConnected) this.#setContent() }
            #setContent() {
                const args = IdenticonSvg.observedAttributes
                                .map(key => this.getAttribute(key) || undefined)
                const memoKey = args.join(',')
                this.innerHTML = IdenticonSvg.#memoized[memoKey] ??=
                    // @ts-ignore
                    identicon(...args)
            }
        }
    )
