
/**
 * @roxi/routify 2.18.0
 * File generated Sat Jun 08 2024 23:50:45 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.0"
export const __timestamp = "2024-06-08T23:50:45.279Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isPage": true,
      "path": "/activity",
      "id": "_activity",
      "component": () => import('../src/pages/activity.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/boost",
      "id": "_boost",
      "component": () => import('../src/pages/boost.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/cypress",
      "id": "_cypress",
      "component": () => import('../src/pages/cypress.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/login",
      "id": "_login",
      "component": () => import('../src/pages/login.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/my",
      "id": "_my",
      "component": () => import('../src/pages/my.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/reactions",
      "id": "_reactions",
      "component": () => import('../src/pages/reactions.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/reports",
      "id": "_reports",
      "component": () => import('../src/pages/reports.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/rules",
      "id": "_rules",
      "component": () => import('../src/pages/rules.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/seek",
      "id": "_seek",
      "component": () => import('../src/pages/seek.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/user",
      "id": "_user",
      "component": () => import('../src/pages/user.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

