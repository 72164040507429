<script>
  import { t, init, locale, waitLocale } from 'svelte-intl-precompile'
  import { registerAll } from '$locales'
  import { Router, afterPageLoad } from '@roxi/routify'
  import { routes } from '../.routify/routes'
  import { onMount, onDestroy } from 'svelte'
  import { capitalize } from '@lib/utils'
  import { auth, user, profile, newMessages, newReactions, endSession } from '@store/session'
  import { supabase, handleError } from '@lib/db'
  import { inject } from '@vercel/analytics'
  import { toast } from '@zerodevx/svelte-toast'
  import { identiconSvg } from '@lib/minidenticons'

  let loading = false
  let authSession = null
  let unloading = false
  const title = 'Spojka.cz ―⁠ Aktivita spojuje'

  // Locale
  registerAll()
  const lang = localStorage.getItem('locale') || 'cs'
  init({ initialLocale: lang, fallbackLocale: 'cs' })

  async function loadUser (userId) {
    const { data, error } = await supabase.from('users').select('id, language, open_conversations, email_reaction, email_messages, badges').eq('id', userId).single()
    if (error) { return handleError($t('error'), error) }
    return data
  }
  async function loadProfile (userId) {
    const { data, error } = await supabase.from('profiles').select('*').eq('user_id', userId).single()
    if (error) { return handleError($t('error'), error) }
    return data
  }
  async function loadReputation (userId) {
    const { data, error } = await supabase.from('reputation').select('rating').eq('user_id', userId)
    if (error) { return handleError($t('error'), error) }
    return data
  }
  async function checkNewReactions () {
    const { data, error } = await supabase.rpc('get_number_of_new_reactions')
    if (error) { return handleError($t('error'), error) }
    return data
  }

  // Auth

  // localStorage.setItem('human', true) // for use in login redirection, to work around email scanners

  supabase.auth.onAuthStateChange(async (state, session) => {
    if (!authSession && (state === 'SIGNED_IN' || state === 'TOKEN_REFRESHED')) {
      authSession = session
      loading = true
      auth.set(session.user)
      $user = await loadUser(session.user.id).catch(console.error)
      if ($user) {
        // if ($user.language) { $locale = $user.language }
        $profile = await loadProfile(session.user.id).catch(console.error)
        const reputationData = await loadReputation($auth.id).catch(console.error)
        $user.reputation = reputationData.map(row => row.rating)
      } else if (unloading === false) {
        console.error('User ' + session.user.id + ' not found')
      }
      loading = false
    } else if (state === 'SIGNED_OUT') {
      authSession = null
      $auth = false
      $user = false
      $profile = false
      $newReactions = 0
      toast.push(capitalize($t('userLoggedOut')))
    }
  })

  window.addEventListener('unload', () => { unloading = true }) // Prevents errors from fetches cancelled by navigation

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
      supabase.auth.getSession() // Refreshes auth session if token gets stale. Hopefully it triggers onAuthStateChange
      const { data } = await supabase.auth.getSession()
      if ($auth && !data.session) { await supabase.auth.signOut().catch(console.error) }
    }
  }

  onMount(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
  })

  onDestroy(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange)
    endSession()
  })

  $afterPageLoad(async () => {
    if (authSession) {
      const newReactionsData = await checkNewReactions().catch(console.error)
      $newReactions = newReactionsData
    }
  })

  // Vercel Analytics
  if (import.meta.env.MODE === 'production') { inject() }

  // Workaround for tree-shaking of minidenticons, as optimizeDeps.include didn't work in production for some reason
  if (Math.random() < 0) { console.log(identiconSvg) }
</script>

<svelte:head>
  <title>{$newMessages || $newReactions ? `(${$newMessages + $newReactions}) ${title}` : title}</title>
  {#if $locale}
    {#await waitLocale($locale).catch(console.error)}
      <meta name='description' content={$t('metaDescription')}>
      <meta name='keywords' content={$t('metaKeywords')}>
    {/await}
  {/if}
</svelte:head>

{#if loading}
  <div class='spinner' data-cy='spinner'></div>
{:else}
  <Router {routes} />
{/if}