import { createClient } from '@supabase/supabase-js'
import { toastError } from '@lib/utils'

const path = import.meta.env.MODE === 'production' ? 'https://isychrgrqvdbtouhyglo.supabase.co' : 'http://127.0.0.1:54321'
const key = import.meta.env.MODE === 'production'
  ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlzeWNocmdycXZkYnRvdWh5Z2xvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE4MzcxMjgsImV4cCI6MTk5NzQxMzEyOH0.D6P43pyOmrOdlyS41DmNMyCusq-AJBUWD86ZvW2uMSo'
  : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'

export const supabase = createClient(path, key, {
  realtime: { params: { eventsPerSecond: 10 } }
})

// prevention of https://ishwar-rimal.medium.com/typeerror-failed-to-fetch-a-k-a-pain-in-the-ass-fa04dda1514c
window.addEventListener('unload', () => { window.isWindowClosed = true })
window.onbeforeunload = () => { window.isWindowClosed = true }

export function handleError (label, error) {
  if (!window.isWindowClosed) { // ignore fetch errors (cancelled fetches) when the page is closing/reloading
    console.error(error)
    toastError(label + ': ' + error.message)
    return Promise.reject(error)
  }
}

export async function upsertIdentity (t, identity) {
  const { data, error } = await supabase.from('identities').upsert(identity, { onConflict: 'id' }).select().single()
  if (error) return handleError(t('error'), error)
  return data
}

export async function updateProfile (t, userId, profile) {
  const { data, error } = await supabase.from('profiles').update(profile).eq('user_id', userId).select().single()
  if (error) return handleError(t('error'), error)
  return data
}

export async function updateOpen (t, user, tabs, minified) {
  const allOpen = [...tabs, ...minified].filter(id => id !== 'contacts')
  const { error } = await supabase.from('users').update([{ open_conversations: allOpen }]).eq('id', user.id)
  if (error) { return handleError(t('error'), error) }
}
