import { toast } from '@zerodevx/svelte-toast'

export function capitalize (str) {
  return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export function getBirthISO (year, month = 1, day = 1) {
  return new Date(Date.UTC(year, month - 1, day)).toISOString()
}

export function getAgeISO (year) {
  return new Date(Date.UTC(new Date().getUTCFullYear() - year)).toISOString()
}

export async function loadScript (url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.onload = () => resolve(script)
    script.onerror = () => reject(script)
    document.head.appendChild(script)
  })
}

export function hasData (obj) {
  for (const key in obj) {
    const val = obj[key]
    if (val === null) continue
    if (typeof val === 'number' || typeof val === 'boolean') return true
    if (Array.isArray(val) && val.length > 0) return true
    if (typeof val === 'string' && val !== '') return true
    if (typeof val === 'object' && hasData(val)) return true
  }
  return false
}

export function deepEqual (x, y) {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty ? (ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))) : (x === y)
}

// export function getAge (day = 1, month = 1, year) {
//   const ageDate = new Date(Date.now() - new Date(Date.UTC(year, month - 1, day)).getTime())
//   return Math.abs(ageDate.getUTCFullYear() - 1970)
// }

export function getAge (ts) { // from timestamp
  const ageDate = new Date(Date.now() - new Date(ts).getTime())
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function getBirth (ts) {
  return { year: new Date(ts).getUTCFullYear(), month: new Date(ts).getUTCMonth() + 1, day: new Date(ts).getUTCDate() }
}

export function isValidDatePart (num, part) {
  const str = num.toString()
  switch (part) {
    case 'year': return str.length === 4 && num > 1900 && num < new Date().getFullYear() // now
    case 'month' : return str.length >= 1 && str.length <= 2 && num >= 1 && num <= 12
    case 'day': return str.length >= 1 && str.length <= 2 && num >= 1 && num <= 31
  }
}

export function toastError (message) {
  toast.push(message, { theme: { '--toastBarBackground': '#E54B4B' } })
}

export function isFilledArray (arr) {
  return Array.isArray(arr) && arr.length > 0
}

export function isFilledObject (obj) {
  return Object.keys(obj).length > 0
}

export function isFilledString (str) {
  return typeof str === 'string' && str.length > 0
}

export function clone (source) { return source ? JSON.parse(JSON.stringify(source)) : source }

export function clickOutsideModal (node) {
  const handleClick = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent('clickOutside', node))
    }
  }
  document.addEventListener('click', handleClick, true)
  return {
    destroy () { document.removeEventListener('click', handleClick, true) }
  }
}

export function preventLetters (e) {
  e.target.value = e.target.value.replace(/[^0-9]/gi, '')
}

// 2DO: figure out how to reuse clickOutsideModal with a param instead of this
export function clickOutsideFilter (node) {
  const handleClick = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented && !event.target.classList.contains('opener')) {
      node.dispatchEvent(new CustomEvent('clickOutside', node))
    }
  }
  document.addEventListener('click', handleClick, true)
  return {
    destroy () { document.removeEventListener('click', handleClick, true) }
  }
}

export function decodeWKB (hexString) {
  // Convert the hexadecimal string to a Uint8Array buffer
  const buffer = new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))

  // Create a new reader for the buffer
  const reader = new DataView(buffer.buffer)

  // Get the byte order of the WKB format (1 = little endian, 0 = big endian)
  const littleEndian = reader.getUint8(0) === 1

  // Get the WKB type (2 = point)
  const type = reader.getUint32(1, littleEndian)

  // Get the SRID value (if present)
  let offset = 5 // Start at byte 5 if there is no SRID value
  if (type & 0x20000000) { offset = 9 } // If there is an SRID value, start at byte 9

  // Get the latitude and longitude coordinates
  const longitude = reader.getFloat64(offset, littleEndian)
  const latitude = reader.getFloat64(offset + 8, littleEndian)

  // Return the coordinates as a JSON object (with optional SRID value)
  return { lat: latitude, lng: longitude }
}

/*
export function fetchBlob (blobUrl, fileName = 'unnamed') {
  return fetch(blobUrl)
    .then(res => res.blob())
    .then(blob => new File([blob], fileName, { type: blob.type, lastModified: new Date().getTime() }))
    .catch(err => console.error(err))
}

export function fileToBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
*/
export function resizePortrait (img, width, height) {
  const { width: imgWidth, height: imgHeight } = img
  const imgRatio = imgWidth / imgHeight
  const desiredRatio = width / height
  const newWidth = imgRatio > desiredRatio ? height * imgRatio : width
  const newHeight = imgRatio > desiredRatio ? height : width / imgRatio
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = newWidth
  canvas.height = newHeight
  ctx.drawImage(img, 0, 0, newWidth, newHeight)
  const cropCanvas = document.createElement('canvas')
  const cropCtx = cropCanvas.getContext('2d')
  cropCanvas.width = width
  cropCanvas.height = height
  const startX = Math.max(0, (newWidth - width) / 2)
  const startY = 0
  cropCtx.drawImage(canvas, startX, startY, width, height, 0, 0, width, height)
  return cropCanvas.toDataURL()
}

// multi-select: go through all items to find the ones selected (keys)
export function getSelected (keys, source) {
  if (Array.isArray(keys) && keys.length > 0) {
    const items = []
    keys.forEach(key => {
      const foundItem = source.find(item => item.value === key)
      if (foundItem) { items.push(foundItem) }
    })
    return items.length > 0 ? items : ''
  } else { return '' }
}

// custom svelte-select ordering of search: https://github.com/rob-balfre/svelte-select/issues/491#issuecomment-1299045128
export async function filterAndSort (searchString, options, key) {
  if (searchString.length === 0) return options
  // Filter array to keep only items where the 'label' starts with the search string
  const filtered = options.filter(item => item[key].toLowerCase().startsWith(searchString.toLowerCase()))

  // Sort the filtered array based on closeness of match to the search string
  return filtered.sort((a, b) => {
    // If both items are equal in terms of matching, retain order
    if (a.label.toLowerCase().indexOf(searchString.toLowerCase()) === b[key].toLowerCase().indexOf(searchString.toLowerCase())) {
      return 0
    }
    // Prioritize items that match from the start of the string
    return a[key].toLowerCase().indexOf(searchString.toLowerCase()) - b[key].toLowerCase().indexOf(searchString.toLowerCase())
  })
}

export function hasAttributes (obj) {
  const groupKeys = ['group_type', 'group_size']
  const personKeys = ['gender', 'birth', 'age_from', 'age_to', 'professions', 'field', 'status', 'orientation', 'languages']
  if (obj.is_group) {
    return groupKeys.some(attr => attr in obj)
  } else {
    // check if key is present, is truthy and not an empty array
    return personKeys.some(attr => obj[attr] && (!Array.isArray(obj[attr]) || obj[attr].length > 0))
  }
}
