import { writable } from 'svelte/store'

export const auth = writable(false)
export const user = writable(false)
export const profile = writable(false)

export const newMessages = writable(0)
export const newReactions = writable(0)

export const identityModalShow = writable(false)
export const identityModalData = writable({})

export const tooltipInstance = writable(null)

// Sync to localStorage

export const seek = writable(localStorage.seek ? JSON.parse(localStorage.seek) : {}) // saved seek
const seekUnsubscribe = seek.subscribe(value => { localStorage.setItem('seek', JSON.stringify(value)) })

export const filter = writable(localStorage.filter ? JSON.parse(localStorage.filter) : {}) // saved filter
const filterUnsubscribe = filter.subscribe(value => { localStorage.setItem('filter', JSON.stringify(value)) })

export const email = writable(localStorage.email || '') // saved email - has to be saved because mobile browsers clean state when leaving app (when going into mail app for auth code)
const emailUnsubscribe = email.subscribe(value => { localStorage.setItem('email', value) })

export function endSession () {
  seekUnsubscribe()
  filterUnsubscribe()
  emailUnsubscribe()
}
